import React from "react"
import "./banner.css"
function Banner({ date, content, image }) {
	return (
		<div className='banner container'>
			<div className='left-section'>
				<div className='banner-text'>{content}</div>
				<div className='date-text'>{date}</div>
			</div>
			<div className='right-section'>
				<div className='img-banner-contianer'>
					<img className='img-banner' src={image} alt='Our Pictures' />
				</div>
			</div>
		</div>
	)
}

export default Banner
