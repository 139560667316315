import React from "react"
import "../indexBanner/frontbanner.css"

function MidBanner() {
	return (
		<div className='mid-banner-container'>
			<div className='overlay-shadow'></div>
			<div className='mid-banner-img'>
				<img
					alt='Mid Banner Collage'
					className='mid-banner-img'
					src='https://res.cloudinary.com/psaha/image/upload/f_auto,q_auto/v1630097162/us/17-03-2021_21_31_01_guikn9.png'
				/>
			</div>
			<div className='big mid-banner-text'>This is us....</div>
		</div>
	)
}

export default MidBanner
