import React from "react"
import Banner from "../banner/Banner"
import FrontBanner from "../indexBanner/FrontBanner"
import MidBanner from "../midbanner/MidBanner"
import BannerData from "../../data/bannerData"
import Footer from "../footer/Footer"
import SecondBanner from "../secondBanner/SecondBanner"

function Homepage() {
	console.log(BannerData)
	return (
		<>
			<FrontBanner />
			<MidBanner />
			<SecondBanner />
			<div className='all-banner-container'>
				{BannerData.map((eachData, index) => {
					return (
						<Banner
							date={eachData.date}
							content={eachData.desc}
							image={eachData.pic}
							key={index}
						/>
					)
				})}
			</div>
			<Footer />
		</>
	)
}

export default Homepage
