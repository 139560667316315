import React from "react"
import "./secondbanner.css"
import Typical from "react-typical"
function SecondBanner() {
	const steps = [
		"2 years of togetherness",
		3000,
		"2 years of us",
		3000,
		"Ups and downs",
		2000,
		"We fought",
		1500,
		"We loved each other",
		3000,
		"Lets relive them",
		2000,
	]
	return (
		<div className='second-banner container'>
			<div className='typical-writting'>
				<Typical
					wrapper='div'
					steps={steps}
					loop={Infinity}
					className='big sec-ban-text'
				/>
			</div>
		</div>
	)
}

export default SecondBanner
