import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import "./App.css"
import Homepage from "./components/homepage/Homepage"
import Navbar from "./components/navbar/Navbar"

function App() {
	return (
		<>
			<Router>
				<Navbar />
				<Switch>
					<Route exact path='/' component={Homepage} />
				</Switch>
			</Router>
		</>
	)
}

export default App
