import React from "react"
import { Link } from "react-router-dom"
import "./navbar.css"
function Navbar() {
	return (
		<div className='navbar'>
			<div className='left'>
				<Link to={"/"}></Link>
				<div className='nav-logo'>
					<Link to={"/"} className='link nav-logo'>
						<i className='fas fa-heart'></i>
					</Link>
				</div>

				<div className='nav-text'>
					<Link to={"/"} className='link nav-text'>
						You and Me
					</Link>
				</div>
			</div>
			<div className='right'></div>
		</div>
	)
}

export default Navbar
