import React from "react"
import Typical from "react-typical"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import "./frontbanner.css"
function FrontBanner() {
	const steps = [
		"Momo",
		2000,
		"Momo Chanda",
		2000,
		"Momochu",
		2000,
		"Momo Vartaski",
		2000,
		"Khepchu",
		2000,
		"love",
		2000,
		"mine forever",
		2000,
		"cutesa momo",
		2000,
		"pyarasa momo",
		2000,
	]
	const piclinks = [
		"https://res.cloudinary.com/psaha/image/upload/f_auto,q_auto/v1630092290/us/IMG_20190915_171023_cbjcbf.jpg",
		"https://res.cloudinary.com/psaha/image/upload/f_auto,q_auto/v1630092288/us/IMG_20191014_155357_1_ocylja.jpg",
		"https://res.cloudinary.com/psaha/image/upload/f_auto,q_auto/v1630092290/us/IMG_20191201_122927_bbcif9.jpg",
		"https://res.cloudinary.com/psaha/image/upload/f_auto,q_auto/v1630092290/us/IMG-20191027-WA0033_1_v8urmj.jpg",
		"https://res.cloudinary.com/psaha/image/upload/f_auto,q_auto/v1630092289/us/IMG-20191214-WA0029_t0ayvq.jpg",
		"https://res.cloudinary.com/psaha/image/upload/f_auto,q_auto/v1630092290/us/IMG_20200301_142524_gwzvs5.jpg",
		"https://res.cloudinary.com/psaha/image/upload/f_auto,q_auto/v1630092290/us/IMG_20200109_171137_ae3qb3.jpg",
		"https://res.cloudinary.com/psaha/image/upload/f_auto,q_auto/v1630092290/us/IMG_20191214_173039_cqdaz5.jpg",
		"https://res.cloudinary.com/psaha/image/upload/f_auto,q_auto/v1630092289/us/IMG_20191112_163145_pth99u.jpg",
		//"https://res.cloudinary.com/psaha/image/upload/f_auto,q_auto/v1630092287/us/IMG_20200120_164303_hqrbye.jpg",
		"https://res.cloudinary.com/psaha/image/upload/f_auto,q_auto/v1630094205/us/1614434517776.img_mnncdq.jpg",
		"https://res.cloudinary.com/psaha/image/upload/f_auto,q_auto/v1630094205/us/20210306_173335_pcneav.jpg",
		//"https://res.cloudinary.com/psaha/image/upload/f_auto,q_auto/v1630094207/us/20210306_164119_pjyjfv.jpg",
		//"https://res.cloudinary.com/psaha/image/upload/f_auto,q_auto/v1630094205/us/20210814_145841_hqx5mh.jpg",
		"https://res.cloudinary.com/psaha/image/upload/f_auto,q_auto/v1630094207/us/IMG_20210814_161936_rbvuwq.jpg",
	]
	return (
		<div className='front-banner container'>
			<div className='left-section'>
				<div className='text'>
					<div className='big'>
						Found you inside my <span style={{ color: "red" }}>heart</span>
					</div>
					<div className='small'>
						You are my{" "}
						<Typical
							wrapper='span'
							steps={steps}
							loop={Infinity}
							className='small'
						/>
					</div>
				</div>
			</div>
			<div className='right-section'>
				<div className='img-slider'>
					<Carousel
						autoPlay
						autoFocus
						infiniteLoop
						showThumbs={false}
						showStatus={false}
					>
						{piclinks.map((eachPic, index) => {
							return (
								<div>
									<img
										className='img-front-banner'
										src={eachPic}
										key={index}
										alt={index}
									/>
								</div>
							)
						})}
					</Carousel>
				</div>
			</div>
		</div>
	)
}

export default FrontBanner
