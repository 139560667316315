const data = [
	{
		date: "August 14, 2021",
		desc: `Your lips so soft and red,
the thought of kissing you is stuck in my head.
Your beauty so bright and warm,
shining through the darkest storm.
Your eyes sparkle like stars in the night sky.
When I stare into them I feel like I am soaring high.
My love for you is pure and true.
I never stop thinking of you.
The sound of your voice saying, “I love you,” makes my heart pound,
because I know my one and only I’ve truly found.
I promise to love you for every moment of forever,
and when everything else crumbles, I will never.
I am your armor to protect you from harm,
like you are to me, a lucky charm.
For you are my heart, my soul.
Baby, you are my whole world.`,
		pic: "https://i.imgur.com/g0Lgz80.jpg",
	},
	{
		date: "August 14, 2021",
		desc: `I promise to Love you through the good times and bad
I’ll Love you when I’m angry, hurt and mad
Love is a choice I’ve made to devote my life
To making you, my world, my wife
Nothing will ever change that choice that I’ve made
Even when we feel our Love start to fade
It’s inside my soul, and nothing can shake
My Love for you, that’s a promise I won’t break`,
		pic: "https://i.imgur.com/0dQqnYZ.jpg",
	},
	{
		date: "April 1, 2021 ",
		desc: `A million stars up in the sky.
One shines brighter - I can't deny.
A love so precious, a love so true,
a love that comes from me to you.
The angels sing when you are near.
Within your arms I have nothing to fear.
You always know just what to say.
Just talking to you makes my day.
I love you, honey, with all of my heart.
Together forever and never to part`,
		pic: "https://i.imgur.com/ycLdXmo.jpg",
	},
	{
		date: "March 18, 2021",
		desc: `Love is like a river,
A never ending stream.
Love is shared by each other
To answer someone's dream.

It's a never ending story;
Love is not a lie.
You can share in all its glory,
For love will never die.

Love is all around you,
The moon and stars above.
Love is a gift from God,
And God is a gift of love.`,
		pic: "https://i.imgur.com/R6fT5ls.jpg",
	},
	{
		date: "March 6, 2021",
		desc: `Take my heart; I'll give it with ease.
Take my hand and walk this journey with me.
Take these scars and heal them all up.
Take these fears and make them vanish when things get tough.
Take this smile and make it stretch so wide.
Take these arms and hold me oh so tight.
Take these feelings and make them real.
At the end, show me how to feel.`,
		pic: "https://i.imgur.com/kF3tCia.jpg",
	},
	{
		date: "March 6, 2021",
		desc: `You are
what I’ve been
searching for,
chasing after,
longing to find,
every star,
moonbeam
and dream,
all of every thing
so infinitely far away
and yet so intimately
here in my arms.`,
		pic: "https://i.imgur.com/JdXYe2M.jpg",
	},
	{
		date: "March 6, 2021",
		desc: `Just one kiss upon your lips,
is enough to make me dream.
The sweet taste of loves desire,
the soft and tender one I admire.

Don't you know what you do?
When you kiss me I feel the moon,
wrapped in your comforting arms
that hold me tight through out the night.

The divine warmth of you body,
trigger feelings deep inside me,
feelings that I've never had before
that keep me longing for much more.`,
		pic: "https://i.imgur.com/aONL2Qd.png",
	},
	{
		date: "March 6, 2021",
		desc: `I think I was searching for treasures or stones
in the clearest of pools
when your face…
when your face,
like the moon in a well
where I might wish…
might well wish
for the iced fire of your kiss;
only on water my lips, where your face…
where your face was reflected, lovely,
not really there when I turned
to look behind at the emptying air…
the emptying air.`,
		pic: "https://i.imgur.com/WW38WDK.jpg",
	},
	{
		date: "March 6, 2021",
		desc: `Like a piece of diamond,
Shining bright like the stars,
Your smile is warming
And your lips so tender.
My heart skips a beat
Not just by the beauty of your face
But your charisma.
The charm in your soul,
Echoing in my head,
Reaching toward me,
Makes me feel like I'm melting
As ice does under the scorching sun.`,
		pic: "https://i.imgur.com/t1HCbhr.jpg",
	},
	{
		date: "February 27, 2021",
		desc: `You supply the lips and I'll supply the kiss,
You supply the chance and I'll supply the bliss.
You supply the truth and I'll supply the trust,
You supply the fantasy and I'll supply the act,
You supply the opinion and I'll supply the fact.
You supply the quip and I'll supply the smile,
You supply the road and I'll supply that extra mile.
You supply the wings and I'll supply the flight,
You supply the day and I'll supply the night.
You supply the ground below and I'll supply the sky above,
You supply your tender heart and I'll supply my endless love.`,
		pic: "https://i.imgur.com/Q2Wtpaj.jpg",
	},
	{
		date: "February 27, 2021",
		desc: `Before I met you,
I felt that I couldn't love anyone,
That nobody would be able to fill the void in my heart,
But that all changed when I met you.
Then I came to realize you were always on my mind.
You're funny and sweet.
You make me laugh and smile.
You take away all my anger and sadness.
You make me weak when I talk to you.
Then I started to write poems about you.
Now I have come to realize that I am hopelessly in love with you`,
		pic: "https://i.imgur.com/iWhbziK.jpg",
	},
	{
		date: "February 11, 2021",
		desc: `You were like the sun who brought light
Into my cold, dark, and lifeless night.
You made me realize that no matter what I do,
My life will always revolve around you.
We are like the sun and the moon.
Far in every way, but I wish to see you soon.
We are miles and miles apart,
But never forget we are always connected in our heart.`,
		pic: "https://i.imgur.com/2dBqw4M.jpg",
	},
	{
		date: "January 27, 2021",
		desc: `I never knew about happiness;
I didn’t think dreams came true;
I couldn’t really believe in love,
Until I finally met you.`,
		pic: "https://i.imgur.com/yJV1jmD.jpg",
	},
	{
		date: "January 13, 2021",
		desc: `You are my angel, my one and only,
My forever love, so we'll never be lonely.
You've brought to my life all your love and care.
It made me see when I realized how rare
It is to meet your angel, or their lifetime one,
Through you, whose life has begun.`,
		pic: "https://i.imgur.com/JA4KliR.jpg",
	},
	{
		date: "December 27, 2021",
		desc: `I don’t need just one day to celebrate our love,
I get to do that every day.
Through kisses, hugs and daily talks,
Nothing can make me stay away`,
		pic: "https://i.imgur.com/g9nbOfr.jpg",
	},
	{
		date: "December 21, 2020",
		desc: `I keep myself busy
with the things I do,
But every time I pause,
I still think of you.`,
		pic: "https://i.imgur.com/X5liEoa.jpg",
	},
	{
		date: "November 15, 2021",
		desc: `It is hard to describe the feeling of love
The feeling of your heart skipping a beat
Or of flying like that of a dove
These are the feelings I have when we meet.`,
		pic: "https://i.imgur.com/GkOaHDC.jpg",
	},
	{
		date: "October 23, 2020",
		desc: `Some words, some letters
Cannot express the love
Love is above everything
Love is that heart racing 
Whenever I see you
Coming towards me
With that smile
Every time I hold you,
The sensation that moves 
In my spine to my heart
Every time I kiss you 
The mystical journey of love
From the lips to the heart...
That defines love 
That expresses love
That is love...`,
		pic: "https://i.imgur.com/KoYJKR6.jpg",
	},
	{
		date: "September 27,2020",
		desc: `Next time we come closer 
I want to hold you
Hold you more and more
A liltle longer, a little tighter
Next time look at me a little longer
See my face, see my eyes
Eyes never lie
Tell me what they say...`,
		pic: "https://i.imgur.com/mr7HXSC.jpg",
	},
]
module.exports = data
