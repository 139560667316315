import React from "react"
import "./footer.css"
function Footer() {
	return (
		<div className='footer-container'>
			<div className='footer copyright'>
				<i className='far fa-copyright'></i>JustUs2021
			</div>
			<div className='socials'>
				<div className='social momo'>
					<div className='title momo'>👧</div>
					<div
						className='content momo'
						onClick={() => {
							window.open("https://www.instagram.com/__fuzziegirl__/")
						}}
					>
						<i className='fab fa-instagram'></i>
					</div>
				</div>
				<div className='social potol'>
					<div className='title potol'>👨</div>
					<div
						className='content potol'
						onClick={() => {
							window.open("https://www.instagram.com/psaha2001/")
						}}
					>
						<i className='fab fa-instagram'></i>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Footer
